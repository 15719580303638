module.exports = {
    apiKey: "AIzaSyBQaDYPpcgeEjj5vjdGEV2R9sCoLAsANMw",
    authDomain: "vrijzinniggrootlier.firebaseapp.com",
    projectId: "vrijzinniggrootlier",
    storageBucket: "vrijzinniggrootlier.appspot.com",
    messagingSenderId: "774131183151",
    appId: "1:774131183151:web:6aca72aa599aba8be3a0da"
}

// module.exports = {
//     apiKey: "AIzaSyBtdXUFqI5zegcAyYtESo48MbZQVpTQBpk",
//     authDomain: "vrijzinniggrootlier-8d170.firebaseapp.com",
//     projectId: "vrijzinniggrootlier-8d170",
//     storageBucket: "vrijzinniggrootlier-8d170.appspot.com",
//     messagingSenderId: "1065476594471",
//     appId: "1:1065476594471:web:e8f3918d6f4b6f476c2702"
// }