<template>
  <teacher-profile @save-teacher="reloadSchools"></teacher-profile>
  <!-- TODO: Add a school -->
  <school-selection :schools="schools"></school-selection>
</template>

<script>
  import SchoolSelection from '../../components/SchoolSelection';
  import TeacherProfile from '../../components/TeacherProfile';

  export default {
    name: 'Profile',
    components: {
      SchoolSelection,
      TeacherProfile
    },
    data() {
        return {
            schools: []
        };
    },
    methods: {
      async reloadSchools() {
        this.schools = await this.$store.dispatch('fetchAllSchools')
        const teachers = await this.$store.dispatch('fetchAllTeachers')
        this.schools.forEach(school => {
          school.teachers.forEach(teacher => {
            const f = teachers.find(t => t.uid === teacher)
            if (f) {
              teacher = f
            }
          })
        })
      }
    },
    async created() {
      await this.reloadSchools()
    }
  }
</script>