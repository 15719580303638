<template>
  <ul class="events">
    <li class="event">
      <div class="time">Lentefeest 2024 <div>zondag 28 april 2024</div></div>
      <h4 class="activity">Leerrijke theatervoorstelling door “Nootzakelijk”</h4>
      <div class="description">
        Onze feestelingen zullen eerst in de spotlights gezet worden tijdens een korte plechtigheid.<br/>
        Nadien kan iedereen genieten van een leerrijke theatervoorstelling van de groep "Nootzakelijk". Zij presenteren vol goesting, met (Lente)kriebels een portie durf en met vertrouwen in elkaar.
        Lente is een klein meisje van 7 jaar dat op zoek gaat naar avontuur.

        <br/><br/>
      </div>
      <div class="location">
        <div>Locatie: Evenementenhal “den Boomgaard”, Antwerpsesteenweg 43, 2520 Broechem</div>
        <div>Uur: Dit vind je terug in je inschrijvingsmail.</div>
        <div>Inkom: feestelingen gratis, kinderen &lt; 3 gratis, kinderen 3j-18j €5, volwassenen €8</div>
      </div>
    </li>
    <li class="event">
      <div class="time">Feest Vrijzinnige Jeugd 2024 <div>zaterdag 27 april 2024</div></div>
      <h2 class="activity">Waardevolle schattentocht</h2>
      <div class="description">
        Onze jongvolwassenen gaan op schattentocht. Niet zomaar een tocht, want zij gaan op zoek naar morele waarden. Door het samen uitvoeren van super coole opdrachten met hun gezin en/of vrienden, zullen zij op hun tocht waardevolle schatten vinden. Eens alle schatten gevonden te hebben, wordt er een plechtig moment voorzien én nog een leuke verrassing.
      </div>
      <div class="location vfj-location">
        <div>Locatie: Evenementenhal “den Boomgaard”, Antwerpsesteenweg 43, 2520 Broechem</div>
        <div>Uur: Dit vind je terug in je inschrijvingsmail.</div>
        <div>Inkom: feestelingen gratis, kinderen &lt; 3 gratis, kinderen 3j-18j €5, volwassenen €8</div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "PageProgram"
}
</script>

<style scoped>
  .event {
    display: grid;
    border-color: #000;
    border-radius: 5px;
    padding: 1rem 1rem 1rem 3rem;
    margin-bottom: 1rem;
    grid-template-columns: 0.4fr 1.6fr;
    grid-template-rows: 0.8fr 1fr 1fr;
    gap: 0 0;
    grid-template-areas:
      "time activity"
      "description description"
      "location location";
  }

  .description {
    grid-area: description;
  }

  .time {
    grid-area: time;
    width: 18rem;
    vertical-align: middle;
    font-weight: bold;
    font-size: large;
  }

  .activity {
    grid-area: activity;
  }

  .location {
    grid-area: location;
    font-weight: bold;
  }

  .vfj-location {
    margin-top: 1rem;
  }

  .event:nth-child(1) {
    background-color: #2fb9b9;
  }

  .event:nth-child(2) {
    background-color: #589fda;
  }
</style>