<template>
  <form @submit.prevent="signIn">
    <div class="mb-3">
      <label for="emailAddress" class="form-label">Emailadres</label>
      <input v-model="form.email" type="email" class="form-control" id="emailAddress">
    </div>
    <div class="mb-3">
      <label for="password" class="form-label">Paswoord</label>
      <input v-model="form.password" type="password" class="form-control" id="password">
    </div>
    <button type="submit" class="btn btn-primary">Inloggen</button>
  </form>
</template>

<script>
export default {
  name: 'Signin',
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    async signIn () {
      try {
        await this.$store.dispatch('auth/signInWithEmailAndPassword', { ...this.form })
        this.successRedirect()
      } catch (error) {
        alert(error.message)
      }
    },
    successRedirect () {
      const redirectTo = this.$route.query.redirectTo || { name: 'Home' }
      this.$router.push(redirectTo)
    }
  }
}
</script>

<style scoped>

</style>