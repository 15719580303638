<template>
  <div
    class="modal fade"
    id="paymentModal"
    ref="paymentModalEltRef"
    tabindex="-1"
    aria-labelledby="paymentModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="paymentModalLabel">
            Betaling voor {{ subscription?.student?.firstName }} {{ subscription?.student?.lastName }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="col-md-6">
            <label for="payedOn" class="form-label">Betaald op</label>
            <input :value="subscription.payedOn" type="date" class="form-control" id="payedOn" ref="payedOn" required>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Sluiten
          </button>
          <button type="button" class="btn btn-primary" @click="saveChanges">Opslaan</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PaymentModal',
    props: {
      subscription: { type: Object }
    },
    emits: ['payedOnChanged'],
    data() {
      return {
      }
    },
    methods: {
      saveChanges() {
        const payedOn = this.$refs.payedOn.value
        this.$emit('payedOnChanged', { payedOn: payedOn, subscriptionId: this.subscription.id })
      }
    }
  }
</script>
