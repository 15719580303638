<template>
  <h5>Aantal leerlingen => <span :class="noVrijzinnig">[Vrijzinnige Feest: {{ vrijzinnigCount }}]</span> <span
      :class="noLentefeest">[Lentefeest: {{ lentefeestCount }}]</span></h5>
  <div class="table-input">
    <button v-on:click="refreshStudents">
      <font-awesome-icon icon="redo" class="fa-solid" aria-hidden="true"/>
      Herladen
    </button>
  </div>
  <div class="table-input">
    <select class="form-select" v-on:change="sortStudents($event)">
      <option value="" selected>--- Selecteer om te sorteren ---</option>
      <option value="feest">Feest</option>
      <option value="city">Gemeente</option>
      <option value="lastName">Achternaam</option>
    </select>
  </div>
  <div>
    <table class="table table-striped">
      <thead>
      <th scope="col">Feest</th>
      <th scope="col">Voornaam</th>
      <th scope="col">Achternaam</th>
      </thead>
      <tbody>
      <tr v-for="student in students" :key="student.id" :value="student.id">
        <td>{{ student.feest }}</td>
        <td>{{ student.firstName }}</td>
        <td>{{ student.lastName }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "StudentLine",
  props: {
    schoolId: {type: String, required: true}
  },
  data() {
    return {
      students: [],
      vrijzinnigCount: 0,
      lentefeestCount: 0,
    }
  },
  computed: {
    noVrijzinnig() {
      return {
        noStudents: this.vrijzinnigCount === 0
      }
    },
    noLentefeest() {
      return {
        noStudents: this.lentefeestCount === 0
      }
    }
  },
  methods: {
    async refreshStudents() {
      this.students = await this.$store.dispatch('fetchStudents', {
        schoolId: this.schoolId
      })
      this.vrijzinnigCount = this.students.filter(student => student.feest === 'Vrijzinnig').length
      this.lentefeestCount = this.students.filter(student => student.feest === 'Lentefeest').length
    },
    sortStudents(evt) {
      this.students = this.students.sort((a, b) => a[evt.target.value] > b[evt.target.value] ? 1 : -1)
    }
  },
  async created() {
    await this.refreshStudents()
  }
}
</script>

<style>
.noStudents {
  color: red;
}

button {
  font-size: 1.5em;
  margin: 5px;
}

.table-input {
  margin-bottom: 1rem;
}
</style>