<template>
  <form @submit.prevent="save" class="row g-3">
    <div class="col-md-6">
      <label for="studentFirstName" class="form-label">Voornaam</label>
      <input v-model="editStudent.firstName" type="text" class="form-control" id="studentFirstName" required>
    </div>
    <div class="col-md-6">
      <label for="studentLastName" class="form-label">Achternaam</label>
      <input v-model="editStudent.lastName" type="text" class="form-control" id="studentLastName" required>
    </div>
    <div class="col-12">
      <select class="form-select" id="studentFeest" v-model="editStudent.feest">
        <option disabled selected value="">Open om een feest te selecteren</option>
        <option value="Vrijzinnig">Vrijzinnig feest</option>
        <option value="Lentefeest">Lentefeest</option>
      </select>
    </div>
    <div class="col-md-9">
    </div>
    <div class="text-left">
      <button type="submit" class="btn btn-primary btn-save">Bewaren</button>
      <button @click.prevent="$emit('cancel')" class="btn btn-danger">Annuleren</button>
    </div>
    <div class="error">
      <span>{{ error }}</span>
    </div>
  </form>
</template>

<script>
export default {
  name: 'NewStudentForm',
  components: {},
  props: {
    schoolId: {type: String, required: true},
    student: {type: Object, required: true}
  },
  data() {
    return {
      error: '',
      editStudent: this.student
    }
  },
  computed: {},
  methods: {
    save() {
      if (this.editStudent.firstName === '' ||
          this.editStudent.lastName === '' ||
          this.editStudent.feest === '')  {
        return
      }
      this.editStudent.FirstNameUpper = this.editStudent.firstName.toUpperCase().trim()
      this.editStudent.firstName = this.editStudent.firstName.trim()
      this.$emit('save-student', { st: this.editStudent })
      this.clearStudent()
    },
    clearStudent() {
      this.editStudent.firstName = ''
      this.editStudent.lastName = ''
    }
  }
}
</script>

<style scoped>
  .btn-save {
    margin-right: 5px;
  }
</style>