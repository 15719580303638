<template>
  <h5 class="table-input">Aantal leerlingen => <span :class="noVrijzinnig">[Vrijzinnige Feest: {{ vrijzinnigCount }}]</span> <span :class="noLentefeest">[Lentefeest: {{lentefeestCount }}]</span></h5>
  <div class="table-input">
    <button v-on:click="refreshStudents"><font-awesome-icon icon="redo" class="fa-solid" aria-hidden="true"/> Herladen</button>
  </div>
  <div class="table-input">
    <select class="form-select" v-on:change="sortStudents($event)">
      <option value="" selected>--- Selecteer om te sorteren ---</option>
      <option value="feest">Feest</option>
      <option value="schoolName">School</option>
      <option value="city">Gemeente</option>
      <option value="lastName">Achternaam</option>
    </select>
  </div>
  <table class="table table-striped">
    <thead>
      <th scope="col">School</th>
      <th scope="col">Feest</th>
      <th scope="col">Voornaam</th>
      <th scope="col">Achternaam</th>
      <th scope="col">Geboortedatum</th>
      <th scope="col">Straat + nr</th>
      <th scope="col">Postcode</th>
      <th scope="col">Gemeente</th>
    </thead>
    <tbody>
      <tr v-for="student in students" :key="student.id" :value="student.id">
        <td>{{ student.schoolName }} te {{ student.schoolCity }}</td>
        <td>{{ student.feest }}</td>
        <td>{{ student.firstName }}</td>
        <td>{{ student.lastName }}</td>
        <td>{{ student.birthDate }}</td>
        <td>{{ student.street }} {{ student.houseNumber }}</td>
        <td>{{ student.postalCode }}</td>
        <td>{{ student.city }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "GlobalOverview",
  data () {
    return {
      students: [],
      schools: [],
      vrijzinnigCount: 0,
      lentefeestCount: 0
    }
  },
  computed: {
    noVrijzinnig() {
      return {
        noStudents: this.vrijzinnigCount === 0
      }
    },
    noLentefeest() {
      return {
        noStudents: this.lentefeestCount === 0
      }
    }
  },
  methods: {
    async refreshStudents() {
      this.students = await this.$store.dispatch('fetchAllStudents', {})
      this.schools = await this.$store.dispatch('fetchAllSchools', {})
      this.students.forEach(student => {
        const school = this.schools.find(s => s.id === student.schoolId)
        student.schoolName = school.name
        student.schoolCity = school.city
      })
      this.students.sort((a, b) => {
        if (a.schoolName === b.schoolName) {
          return a.feest > b.feest ? 1 : -1
        }
        return a.schoolName > b.schoolName ? 1 : -1
      })
      this.vrijzinnigCount = this.students.filter(student => student.feest === 'Vrijzinnig').length
      this.lentefeestCount = this.students.filter(student => student.feest === 'Lentefeest').length
    },
    sortStudents(evt) {
      this.students = this.students.sort((a, b) => a[evt.target.value] > b[evt.target.value] ? 1 : -1)
    }
  },
  async created() {
    await this.refreshStudents()
  }
}
</script>

<style scoped>
  .noStudents {
    color: red;
  }

  .table-input {
    margin-bottom: 1rem;
  }
</style>