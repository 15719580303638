<template>
  <header>
    <nav class="navbar navbar-dark navbar-expand-lg bg-dark">
      <div class="container-fluid">
        <div class="navbar-header">
          <font-awesome-icon icon="fire" class="fa-inverse" />
          <button class="navbar-toggler collapsed" type="button">
            <span class="navbar-toggler-icon"></span>
          </button>
          <router-link :to="{name: 'Home'}" class="active navbar-brand">Vrijzinnige Feesten Groot Lier</router-link>
        </div>
        <div class="collapse navbar-collapse">
          <ul class="nav navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item active">
              <router-link :to="{name: 'Home'}" class="active nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'WorkGroup'}" class="nav-link">Werkgroep</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'Program'}" class="nav-link">Programma</router-link>
            </li> 
            <!-- <li class="nav-item">
              <router-link :to="{name: 'HowToSubscribe'}" class="nav-link">Hoe schrijf ik in</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'Subscription'}" class="nav-link">Inschrijven</router-link>
            </li> -->
            <li class="nav-item">
              <router-link :to="{name: 'Photos'}" class="nav-link">Foto's</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'Contact'}" class="nav-link">Contact</router-link>
            </li>
          </ul>
          <ul class="nav navbar-nav">
            <li class="nav-item">
              <div class="btn-nav"><router-link :to="{name: 'Admin'}" class="nav-link">Admin</router-link></div>
            </li>
            <li v-if="isLoggedIn" class="nav-item">
              <div class="btn-nav"><router-link :to="{name: 'Signout'}" class="nav-link">Uitloggen</router-link></div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <main class="container mt-4">
    <router-view />
    <teleport to="body">
      <vue3-snackbar bottom right :duration="4000"></vue3-snackbar>
    </teleport>
  </main>
  <footer class="page-footer">
    <div class="container mt-4">
      <p>
        Welkom op de vrijzinnige feesten van Lier! (c) 2023 | 2.1
      </p>
    </div>
  </footer>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'App',
  methods: {
    ...mapActions('auth', ['fetchAuthUser']),
  },
  data() {
    return {
      isLoggedIn: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Vrijzinnig Groot Lier'
      }
    },
    '$store.state.auth.authId': {
      immediate: true,
      handler(id) {
        if (id) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      }
    }
  },
  created () {
    this.fetchAuthUser();
  }
}
</script>

<style>
html {
  font-family: sans-serif;
  line-height: 1.15;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  font-family: "Poppins", "Palatino Linotype", "Palatino LT STD", "Book Antiqua",sans-serif;
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
}

.page-footer {
  padding: 20px;
  margin-top: 50px;
  color: #000;
  background-color: #e6f2ff;
  border-top: 1px solid #99caff;
}
</style>
