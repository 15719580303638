<template>
  <h1>Contactformulier</h1>
  <div class="mb-3">
    <label for="contactEmailInput" class="form-label">Email address</label>
    <input type="email" class="form-control" id="contactEmailInput" v-model="emailAddress" placeholder="name@example.com">
  </div>
  <div class="mb-3">
    <label for="contactMessageInput" class="form-label">Geef hier je vraag of opmerking in...</label>
    <textarea class="form-control" id="contactMessageInput" v-model="message" rows="5"></textarea>
  </div>
  <div class="mb-3">
    <button @click="sendMail">Verzenden</button>
  </div>
</template>

<script>
export default {
  name: "PageContact",
  data() {
    return {
      emailAddress: '',
      message: ''
    }
  },
  methods: {
    async sendMail() {
      const content = { email: this.emailAddress, message: this.message }
      const HEADERS = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-functions-key': process.env.VUE_APP_FUNCTION_KEY
      }

      await fetch(`${process.env.VUE_APP_API_URL}/api/SendContactMail`,
          { headers: HEADERS, method: 'POST', body: JSON.stringify(content) })

      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style scoped>

</style>