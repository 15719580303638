<template>
  <div class="container students">
    <div class="row school-select">
      <div class="col">
        <select class="form-select" @change="onSchoolChange">
          <option disabled selected value="">Selecteer een school</option>
          <option v-for="school in schools" :key="school.id" :value="school.id">
            {{ school.name }} {{ school.city }}
          </option>
        </select>
      </div>
    </div>
    <div class="row" v-if="!editDisabled">
      <div class="col">
        <table class="table table-striped">
          <thead>
          <th scope="col">Voornaam</th>
          <th scope="col">Naam</th>
          <th scope="col">Feest</th>
          <th scope="col"></th>
          </thead>
          <tbody>
          <tr v-for="student in students" :key="student.id">
            <td class="align-middle">{{ student.firstName }}</td>
            <td class="align-middle">{{ student.lastName }}</td>
            <td class="align-middle">{{ student.feest }}</td>
            <td>
              <button class="btn btn-light" @click="deleteStudent(student.id)">
                <font-awesome-icon icon="trash" class="fa-solid" aria-hidden="true"/>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col">
        <new-student-form :key="reload" @save-student="addStudent" :student="student" :school-id="schoolId"></new-student-form>
      </div>
    </div>
  </div>
</template>

<script>
import NewStudentForm from "../../components/NewStudentForm";

export default {
  name: "Students",
  components: {
    NewStudentForm
  },
  data() {
    return {
      reload: 0,
      students: [],
      studentCount: [],
      schoolId: '',
      schools: [],
      editDisabled: true,
      student: {
        firstName: '',
        lastName: '',
        feest: '',
        schoolId: ''
      }
    }
  },
  async created() {
    this.schools = await this.$store.dispatch('fetchSchoolsOfTeacher')
  },
  methods: {
    async addStudent() {
      // TODO : check if the student exists or not
      await this.$store.dispatch('createStudent', {
        firstName: this.student.firstName,
        lastName: this.student.lastName,
        feest: this.student.feest,
        FirstNameUpper: this.student.FirstNameUpper,
        schoolId: this.schoolId
      })
      await this.loadStudents()
    },
    async deleteStudent(id) {
      await this.$store.dispatch('deleteStudent', id)
      await this.loadStudents()
    },
    async onSchoolChange(event) {
      this.schoolId = event.target.value;
      this.editDisabled = false
      await this.loadStudents()
    },
    async loadStudents() {
      this.students = await this.$store.dispatch('fetchStudents', {
        schoolId: this.schoolId
      })
      this.studentCount = await this.$store.dispatch('fetchStudentsByFeest', {
        schoolId: this.schoolId,
        feest: 'Vrijzinnig'
      })
    }
  }
}
</script>

<style scoped>
.students {
  margin-top: 2rem;
}

.school-select {
  margin-bottom: 2rem;
}
</style>