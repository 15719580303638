<template>
  <form @submit.prevent="save" class="row g-3">
    <h3>Gegevens voor email {{ teacherMail }}</h3>
    <div class="col-md-6">
      <label for="studentFirstName" class="form-label">Voornaam</label>
      <input v-model="teacher.firstName" type="text" class="form-control" id="firstName" required>
    </div>
    <div class="col-md-6">
      <label for="studentLastName" class="form-label">Achternaam</label>
      <input v-model="teacher.name" type="text" class="form-control" id="lastName" required>
    </div>
    <div class="text-left">
      <button type="submit" class="btn btn-primary btn-save">Bewaren</button>
      <button @click.prevent="$emit('cancel')" class="btn btn-danger">Annuleren</button>
    </div>
    <div class="error">
      <span>{{ error }}</span>
    </div>
  </form>
</template>
<script>
  export default {
    name: 'TeacherProfile',
    data() {
      return {
        error: '',
        teacherMail: '',
        teacherUid: '',
        teacher: {}
      }
    },
    async created() {
      this.teacherUid = this.$store.state.auth.authId
      this.teacherMail = this.$store.state.auth.email
      const authId = this.$store.state.auth.authId
      this.teacher = await this.$store.dispatch('fetchTeacher', { uid: authId })
    },
    methods: {
      async save() {
        this.teacher.uid = this.teacherUid
        await this.$store.dispatch('createTeacher', this.teacher)
        this.$emit('save-teacher', {})
      },
    }
  }
</script>