<template>
  <tr
    v-for="subscription in subscriptions"
    :key="subscription.id"
    :value="subscription.id"
  >
    <td>
      {{ subscription.student.firstName }} {{ subscription.student.lastName }}
    </td>
    <td>{{ subscription.parentName }}</td>
    <td>{{ subscription.student.feest }}</td>
    <td>{{ schoolName }} te {{ schoolCity }}</td>
    <td>{{ subscription.email }}</td>
    <td>{{ subscription.telephone }}</td>
    <td>
      {{ subscription.street }} {{ subscription.houseNumber }},
      {{ subscription.postalCode }} {{ subscription.city }}
    </td>
    <td>{{ subscription.adultAttendees }}</td>
    <td>{{ subscription.childAttendees }}</td>
    <td>{{ subscription.payedOn }}</td>
    <td>{{ subscription.attest ? "Ja" : "Nee" }}</td>
    <td>
      <button class="btn grid-btn"
        @click="attestSent(subscription.id)"
        title="Verzenden attest"
        :class="{
          success: subscription.attestSent,
          danger: !subscription.attestSent,
        }">
        <font-awesome-icon
          icon="envelope-open-text"
          class="fa-solid"
          aria-hidden="true"
        />
      </button>
      <button
        class="btn static-class grid-btn"
        @click="settingPayment(subscription.id)"
        title="Verzenden betalingsmail"
        :class="{ success: subscription.payed, danger: !subscription.payed }">
        <font-awesome-icon
          icon="euro-sign"
          class="fa-solid"
          aria-hidden="true"
        />
      </button>
      <button
        class="btn static-class grid-btn"
        @click="resendSubMail(subscription)"
        title="Herzenden inschrijvingsmail">
        <font-awesome-icon
          icon="paper-plane"
          class="fa-solid"
          aria-hidden="true"
        />
      </button>
      <button
        class="btn static-class grid-btn"
        title="Aanpassen deelnemers"
        @click="openAttendeesDialog(subscription)">
        <font-awesome-icon icon="child" class="fa-solid" aria-hidden="true" />
      </button>
      <button
        class="btn static-class grid-btn"
        title="Uitschrijven"
        @click="deleteSubscription(subscription.id)">
        <font-awesome-icon icon="trash" class="fa-solid" aria-hidden="true" />
      </button> 
      <button
        class="btn static-class grid-btn"
        title="Finale mail verzenden"
        @click="sendFinalMail(subscription.id)">
        <font-awesome-icon icon="flag-checkered" class="fa-solid" aria-hidden="true" />
      </button>
    </td>
  </tr>
  <attendees-modal :subscription="updatingSubscription" ref="attendeesModelRef" @attendees-changed="updateAttendees" />
  <payment-modal :subscription="updatingSubscription" ref="paymentModelRef" @payed-on-changed="updatePayment" />
</template>
<script>
import { Modal } from "bootstrap"
import AttendeesModal from './AttendeesModal.vue'
import PaymentModal from './PaymentModal.vue'

export default {
  components: { AttendeesModal, PaymentModal },
  name: "AllSubscriptionLine",
  props: {
    schoolId: { type: String, required: true },
    schoolName: { type: String, required: true },
    schoolCity: { type: String, required: true }  
  },
  data() {
    return {
      updatingSubscription: {},
      subscriptions: [],
      modal: {}
    };
  },
  methods: {
    async settingPayment(subscriptionId) {
      this.updatingSubscription = this.subscriptions.find((x) => x.id === subscriptionId);
      this.modal = new Modal(this.$refs.paymentModelRef.$refs.paymentModalEltRef)
      this.modal.show()
    },
    async updatePayment(event) {
      let selectedSub = this.subscriptions.find((x) => x.id === event.subscriptionId);
      selectedSub.payed = true;
      selectedSub.payedOn = event.payedOn
      await this.$store.dispatch("payedSubscription", { subscriptionId: event.subscriptionId, payedOn: selectedSub.payedOn });

      const HEADERS = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-functions-key': process.env.VUE_APP_FUNCTION_KEY
      }

      await fetch(`${process.env.VUE_APP_API_URL}/api/SendPayMail`,
          { headers: HEADERS, method: 'POST', body: JSON.stringify(selectedSub) })
      this.modal.hide()
      this.$snackbar.add({
        type: 'success',
        text: `Betalingsmail is verzonden naar ${selectedSub.email}`
      })
    },
    async resendSubMail(subscription) {
      const HEADERS = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-functions-key': process.env.VUE_APP_FUNCTION_KEY
      }

      await fetch(`${process.env.VUE_APP_API_URL}/api/SendMail`,
          { headers: HEADERS, method: 'POST', body: JSON.stringify(subscription) })
      this.$snackbar.add({
        type: 'success',
        text: `Inschrijvingsmail is opnieuw verzonden naar ${subscription.email}`
      })
    },
    async attestSent(subscriptionId) {
      let selectedSub = this.subscriptions.find((x) => x.id === subscriptionId);
      selectedSub.attestSent = true;

      const HEADERS = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-functions-key': process.env.VUE_APP_FUNCTION_KEY
      }

      await fetch(`${process.env.VUE_APP_API_URL}/api/SendAttestMail`,
          { headers: HEADERS, method: 'POST', body: JSON.stringify(selectedSub) })

      await this.$store.dispatch("attestSent", subscriptionId)

      this.$snackbar.add({
        type: 'success',
        text: `Attest is verzonden naar ${selectedSub.email}`
      })
    },
    openAttendeesDialog(subscription) {
      this.updatingSubscription = subscription
      this.modal = new Modal(this.$refs.attendeesModelRef.$refs.attendeesModalEltRef)
      this.modal.show()
    },
    async updateAttendees(event) {
      let subscription = this.subscriptions.find(s => s.id === event.subscriptionId)
      subscription.adultAttendees = event.adultAttendees
      subscription.childAttendees = event.childAttendees
      await this.$store.dispatch('updateAttendees', event)
      this.modal.hide()
      this.$snackbar.add({
        type: 'success',
        text: `De aantallen zijn aangepast voor ${subscription.student.firstName} ${subscription.student.lastName}`
      })
    },
    async deleteSubscription(subscriptionId) {
      if (confirm("Ben je zeker om de inschrijving te verwijderen")) {
        await this.$store.dispatch("deleteSubscription", subscriptionId);
        await this.loadSubscriptions();
        this.$snackbar.add({
          type: 'success',
          text: `De inschrijving is verwijderd`
        })
      }
    },
    async sendFinalMail(subscriptionId) {
      let selectedSub = this.subscriptions.find((x) => x.id === subscriptionId);

      const HEADERS = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-functions-key': process.env.VUE_APP_FUNCTION_KEY
      }

      await fetch(`${process.env.VUE_APP_API_URL}/api/SendFinalMail?email=${selectedSub.email}`,
          { headers: HEADERS, method: 'POST' })
      this.$snackbar.add({
        type: 'success',
        text: `Finale mail is verzonden naar ${selectedSub.email}`
      })
    },
    async loadSubscriptions() {
      this.subscriptions = await this.$store.dispatch("fetchSubscriptions", {
        schoolId: this.schoolId,
      });
    }
  },
  async created() {
    this.subscriptions = await this.$store.dispatch("fetchSubscriptions", {
      schoolId: this.schoolId,
    });
  }
};
</script>
<style scoped>
.success {
  color: green;
}
.danger {
  color: red;
}

.grid-btn {
  padding: 0;
  margin: 0;
}
</style>
