<template>
  <div
    class="modal fade"
    id="attendeesModal"
    ref="attendeesModalEltRef"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Aantal personen voor {{ subscription?.student?.firstName }} {{ subscription?.student?.lastName }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="col-md-2">
            <label for="adultAttendees" class="form-label">Aantal volwassenen</label>
            <input :value="subscription.adultAttendees" type="number" class="form-control" id="adultAttendees" ref="adultAttendees" required>
          </div>
          <div class="col-md-2">
            <label for="childAttendees" class="form-label">Aantal kinderen</label>
            <input :value="subscription.childAttendees" type="number" class="form-control" id="childAttendees" ref="childAttendees" required>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Sluiten
          </button>
          <button type="button" class="btn btn-primary" @click="saveChanges">Opslaan</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AttendeesModal',
    props: {
      subscription: { type: Object }
    },
    emits: ['attendeesChanged'],
    data() {
      return {
      }
    },
    methods: {
      saveChanges() {
        const adultAttendees = this.$refs.adultAttendees.value
        const childAttendees = this.$refs.childAttendees.value
        this.$emit('attendeesChanged', { adultAttendees: adultAttendees, childAttendees: childAttendees, subscriptionId: this.subscription.id })
        
      }
    }
  }
</script>
