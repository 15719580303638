import PageHome from "@/pages/Home"
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Workgroup from "@/pages/Workgroup"
import Program from "@/pages/Program"
// import HowToSubscribe from "@/pages/HowToSubscribe"
import Photos from "@/pages/Photos"
import Contact from "@/pages/Contact"
import Signin from "@/pages/Signin"
import Signout from "@/pages/Signout"
import Admin from "@/pages/admin/Dashboard"
// import Subscription from "@/pages/Subscription"

const routes = [
    { path: '/', name: 'Home', component: PageHome },
    { path: '/teachers', name: 'WorkGroup', component: Workgroup },
    { path: '/program', name: 'Program', component: Program },
    // { path: '/howtosub', name: 'HowToSubscribe', component: HowToSubscribe },
    // { path: '/subscription', name: 'Subscription', component: Subscription },
    { path: '/photos', name: 'Photos', component: Photos },
    { path: '/contact', name: 'Contact', component: Contact },
    { path: '/signin', name: 'Signin', component: Signin },
    { path: '/signout', name: 'Signout', component: Signout },
    { path: '/admin', name: 'Admin', component: Admin, meta: { requiresAuth: true } }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach(async (to) => {
    await store.dispatch('auth/initAuthentication')
    if (to.meta.requiresAuth && !store.state.auth.authId) {
        return { name: 'Signin', query: { redirectTo: to.path } }
    }
})

export default router

