<template>
  <ul class="nav nav-tabs">
    <li class="nav-item" :class="['tab-button', { active: currentTabComponent === 'Overview' }]">
      <button class="nav-link tab-button" @click="currentTabComponent = 'Overview'">Overzicht</button>
    </li>
    <li class="nav-item" :class="['tab-button', { active: currentTabComponent === 'AdminSubscriptions' }]">
      <button class="nav-link tab-button" @click="currentTabComponent = 'AdminSubscriptions'">Inschrijvingen</button>
    </li>
    <li v-if="isAdmin" class="nav-item" :class="['tab-button', { active: currentTabComponent === 'GlobalOverview' }]">
      <button class="nav-link tab-button" @click="currentTabComponent = 'GlobalOverview'">Globaal Overzicht</button>
    </li>
    <li v-if="isAdmin" class="nav-item" :class="['tab-button', { active: currentTabComponent === 'AdminAllSubscriptions' }]">
      <button class="nav-link tab-button" @click="currentTabComponent = 'AdminAllSubscriptions'">Alle Inschrijvingen</button>
    </li>
    <li class="nav-item" :class="['tab-button', { active: currentTabComponent === 'Students' }]">
      <button class="nav-link tab-button" @click="currentTabComponent = 'Students'">Leerlingen</button>
    </li>
    <!-- <li v-if="isAdmin" class="nav-item" :class="['tab-button', { active: currentTabComponent === 'Profile' }]">
      <button class="nav-link tab-button" @click="currentTabComponent = 'Profile'">Profiel</button>
    </li> -->
  </ul>
  <keep-alive>
    <component :is="currentTabComponent"></component>
  </keep-alive>
</template>

<script>
import Students from "./Students"
import Overview from "./Overview"
import GlobalOverview from "./GlobalOverview"
import AdminSubscriptions from "./TeacherSubscriptions"
import AdminAllSubscriptions from "./AllSubscriptions"
import Profile from "./Profile.vue"

export default {
  name: 'Admin.vue',
  components: {
    Students,
    GlobalOverview,
    Overview,
    AdminSubscriptions,
    AdminAllSubscriptions,
    Profile
  },
  data() {
    return {
      isAdmin: false,
      currentTabComponent: 'Overview'
    }
  },
  methods: {
  },
  async created() {
    const administrators = await this.$store.dispatch('fetchAdmin', {})
    if (administrators.length === 1 && administrators[0].allschools) {
      this.isAdmin = true
    }
  }
}
</script>

<style scoped>
.tab-button:hover {
  background: #e0e0e0;
}
.tab-button.active {
  background: #e0e0e0;
}
</style>