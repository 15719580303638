<template>
  <h1>Feest vrijzinnige jeugd</h1>

  <div v-for="(row, rowIndex) in groupedFvjPhotos" :key="rowIndex" class="row">
    <div v-for="photo in row" :key="photo.url" class="col-md-4">
      <div><img :src="photo.url" class="card-img-top" alt=""></div>
    </div>
  </div>

  <h1>Lentefeest</h1>

  <div v-for="(row, rowIndex) in groupedLfPhotos" :key="rowIndex" class="row">
    <div v-for="photo in row" :key="photo.url" class="col-md-4">
      <div><img :src="photo.url" class="card-img-top" alt=""></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PagePhotos",
  data() {
    return {
      fvjPhotos: null,
      lfPhotos: null,
    };
  },
  computed: {
    groupedFvjPhotos() {
      const groupSize = 3
      const grouped = []

      if (this.fvjPhotos != null) {
        for (let i = 0; i < this.fvjPhotos.length; i += groupSize) {
          grouped.push(this.fvjPhotos.slice(i, i + groupSize));
        }
      }

      return grouped
    },
    groupedLfPhotos() {
      const groupSize = 3
      const grouped = []

      if (this.lfPhotos != null) {
        for (let i = 0; i < this.lfPhotos.length; i += groupSize) {
          grouped.push(this.lfPhotos.slice(i, i + groupSize));
        }
      }

      return grouped
    }
  },
  async created() {
    const HEADERS = {
        'x-functions-key': process.env.VUE_APP_FUNCTION_KEY
    }

    const res = await fetch(`${process.env.VUE_APP_API_URL}/api/GetPhotoUrls`,
          { headers: HEADERS, method: 'GET' })

    const result = await res.json()
    this.fvjPhotos = result.filter(i => i.prefix == 'FVJ')
    this.lfPhotos = result.filter(i => i.prefix == 'Lentefeest')
  },
}
</script>

<style scoped>
  .row {
    margin-bottom: 2rem;
  }
</style>