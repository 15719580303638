<template>
  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="col">
      <div class="card">
        <img src="../assets/katia.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Katia Hendrickx</h5>
          <div class="card-text card-schools">
            <div>GO! Alice Nahon Putte</div>
            <div>GO! Bisterveld Kessel</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card">
        <img src="../assets/sallie.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Sallie Crokaerts</h5>
          <div class="card-text card-schools">
            <div>GO! Ten Dorpe Mortsel</div>
            <div>GO! De Bolster Mortsel</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col">
      <div class="card">
        <img src="../assets/eline.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Eline Ouwendijk</h5>
          <div class="card-text card-schools">
            <div>GO! Leefschool Dagpauwoog Koningshooikt</div>
            <div>GO! de Boomgaard Wommelgem</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="col">
      <div class="card">
        <img src="../assets/nadine.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Nadine Vereycken</h5>
          <div class="card-text card-schools">
            <div>GBS Jan Frans Willemschool Boechout</div>
            <div>GBS de Klinker Borsbeek</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <img src="../assets/ines.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Ines Verlooy</h5>
          <div class="card-text card-schools">
            <div>GOEZO Kerkeblokken Nijlen</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card" >
        <img src="../assets/anja.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Anja Vanmeerbergen</h5>
          <div class="card-text card-schools">
            <div>GO! Kiliaan Duffel</div>
            <div>GO! Klim Op Bonheiden</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="col">
      <div class="card">
        <img src="../assets/sabrina2.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Sabrina Van Oosten</h5>
          <div class="card-text card-schools">
            <div>BSBO de Balderschool Berlaar</div>
            <div>BSBO 't Vestje Lier</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <img src="../assets/inge.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Inge Verhees</h5>
          <div class="card-text card-schools">
            <div>GO! Het Molentje Lier</div>
            <div>GO! De Spits Antwerpen Linkeroever</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <img src="../assets/eveline.png" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Eveline Beerts</h5>
          <div class="card-text card-schools">
            <div>GBS De Stap Berlaar</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="col">
      <div class="card">
        <img src="../assets/ben2.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Ben Kovalewski</h5>
          <div class="card-text card-schools">
            <div>GBS Zandhoven</div>
            <div>De Vesten Herentals</div>
          </div>
        </div>
      </div>
    </div>    

    <div class="col">
      <div class="card">
        <img src="../assets/unknown_man.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Geert Smets</h5>
          <div class="card-text card-schools">
            <div>GBS Berlaar Heikant</div>
          </div>
        </div>
      </div>
    </div>    

    <div class="col">
      <div class="card">
        <img src="../assets/leen.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Leen Roels</h5>
          <div class="card-text card-schools">
            <div>Goezo! Kessel</div>
            <div>Goezo! Klokkelaan</div>
          </div>
        </div>
      </div>
    </div>  
  </div>

  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="col">
      <div class="card">
        <img src="../assets/julie.jpeg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Julie Janssens</h5>
          <div class="card-text card-schools">
            <div>Albrecht Rodenbachschool Hove</div>
          </div>
        </div>
      </div>
    </div>  

    <div class="col">
      <div class="card">
        <img src="../assets/unknown_woman.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Julie Coulon</h5>
          <div class="card-text card-schools">
            <div>GBS Andreas Vesalius Edegem</div>
          </div>
        </div>
      </div>
    </div>  

    <div class="col">
      <div class="card">
        <img src="../assets/christine.png" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Christine Nulens</h5>
          <div class="card-text card-schools">
            <div>GBS De Knipoog Vorselaar</div>
            <div>GBS De Driehoek Oelegem</div>
          </div>
        </div>
      </div>
    </div> 
  </div>

  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="col">
      <div class="card">
        <img src="../assets/sophie.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Sophie Vandooren</h5>
          <div class="card-text card-schools">
            <div>GO! 't Kofschip Edegem</div>
            <div>A-HA! Kontich</div>
          </div>
        </div>
      </div>
    </div> 

    <div class="col">
      <div class="card">
        <img src="../assets/sara.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Sara Van Lysebetten</h5>
          <div class="card-text card-schools">
            <div>GO! Blik Edegem</div>
            <div>GO! De Lotusbloem Berlaar</div>
            <div>GO! Stadspark Lier</div>
          </div>
        </div>
      </div>
    </div> 

    <div class="col">
      <div class="card">
        <img src="../assets/eliane.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Eliane Verschooren</h5>
          <div class="card-text card-schools">
            <div>GBS Andreas Vesalius Edegem</div>
            <div>GBS 't Kroontje Massenhoven</div>
          </div>
        </div>
      </div>
    </div> 
  </div>

  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="col">
      <div class="card">
        <img src="../assets/kristien2.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Kristien Dieltiens</h5>
          <div class="card-text card-schools">
            <div>GBS Zandhoven</div>
            <div>GBS de Sleutel Broechem</div>
          </div>
        </div>
      </div>
    </div> 

    <div class="col">
      <div class="card">
        <img src="../assets/helga.jpg" class="card-img-top pic-size mx-auto" alt="" />
        <div class="card-body">
          <h5 class="card-title">Helga Obbels</h5>
          <div class="card-text card-schools">
            <div>BSBO 't Vestje Lier</div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script lang="js">
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import * as Bootstrap from 'bootstrap'

export default {
  mounted() {
    const myCarousel = document.querySelector('#carouselExampleIndicators');
    new Bootstrap.Carousel(myCarousel)
  }
}
</script>

<style scoped>
.row {
  margin-bottom: 1rem;
}

.pic-size {
  max-width: 10rem;
  margin-top: 1rem;
  height: 12rem;
}

.card-schools {
  height: 5rem;
}
</style>


