<template>
  <button class="btn btn-primary" @click="exportToExcel()">Exporteer naar Excel</button>
  <table class="table table-striped all-screen">
    <thead>
      <th scope="col">Leerling</th>
      <th scope="col">Ouder(s)</th>
      <th scope="col">Evenement</th>
      <th scope="col">School</th>
      <th scope="col">Email</th>
      <th scope="col">Telefoon</th>
      <th scope="col">Adres</th>
      <th scope="col">Aantal<br/>Volwassenen</th>
      <th scope="col">Aantal<br/>kinderen</th>
      <th scope="col">Betaald op</th>
      <th scope="col">Attest</th>
      <th></th>
    </thead>
    <tbody>
      <template v-for="school in schools" :key="school.id">
        <all-subscription-line
          :schoolId="school.id"
          :schoolName="school.name"
          :schoolCity="school.city"
        />
      </template>
    </tbody>
  </table> 
</template>
<script>
import AllSubscriptionLine from "../../components/AllSubscriptionLine.vue"
import saveAs from "file-saver"
export default {
  components: { AllSubscriptionLine },
  name: "AdminAllSubscriptions",
  data() {
    return {
      reportAnnouncement: '',
      schools: []
    }
  },
  methods: {
    async exportToExcel() {
      const HEADERS = {
        'x-functions-key': process.env.VUE_APP_FUNCTION_KEY
      }

      this.$snackbar.add({
        type: 'success',
        text: `Uw rapport wordt nu gegenereerd!`
      })

      const res = await fetch(`${process.env.VUE_APP_API_URL}/api/CreateAllSubReport`,
          { headers: HEADERS, method: 'GET' })

      const uri = await res.text()
      saveAs(uri, 'rapport2022.xlsx')
    }
  },
  async created() {
    this.schools = await this.$store.dispatch("fetchAllSchools");
  },
};
</script>

<style scoped>
.all-screen {
  width: 90vw;
  position: relative;
  left: 55%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
</style>