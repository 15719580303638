<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <table class="table table-striped">
          <thead>
            <th scope="col">Naam</th>
            <th scope="col">Gemeente</th>
            <th scope="col">Leraars</th>
            <th scope="col"></th>
          </thead>
          <tbody>
            <tr v-for="school in schools" :key="school.id">
              <td class="align-middle">{{ school.name }}</td>
              <td class="align-middle">{{ school.city }}</td>
              <td class="align-middle">
                <ul>
                  <li v-for="teacher in school.teachers" :key="teacher.id">
                    <span>{{ teacher.firstName }} {{ teacher.name }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <button class="btn btn-light" @click="addTeacher(school.id)">
                  <font-awesome-icon icon="plus" class="fa-solid" aria-hidden="true"/>
                </button>
                <button class="btn btn-light" @click="removeTeacher(school.id)">
                  <font-awesome-icon icon="minus" class="fa-solid" aria-hidden="true"/>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SchoolSelection",
    props: {
      schools: {type: Array, required: true},
    },
    data() {
      return {
        teacher: {}
      }
    },
    async created() {
      const authId = this.$store.state.auth.authId
      this.teacher = await this.$store.dispatch('fetchTeacher', { uid: authId })
    },
    methods: {
        filterTeachers(teachers) {
          return teachers.find(t => t.name !== '')
        },
        addTeacher(schoolId) {
            let currentSchool = this.schools.find(school => school.id === schoolId)
            let currentTeacher = currentSchool.teachers.find(t => t.id === this.teacher.id)
            if (!currentTeacher) {
              currentTeacher = currentSchool.teachers.find(t => t === this.teacher.uid)
              if (!currentTeacher) {
                currentSchool.teachers.push(this.teacher)
                this.$store.dispatch('updateTeachers', { schoolId: currentSchool.id, teachers: currentSchool.teachers })
              }
            }
        },
        removeTeacher(schoolId) {
          let currentSchool = this.schools.find(school => school.id === schoolId)
          let teacherIndex = currentSchool.teachers.findIndex(t => t.id === this.teacher.id)
          if (teacherIndex !== -1) {
            currentSchool.teachers.splice(teacherIndex, 1)
          }
        }
    },
}
</script>