<template>
  <section class="jumbotron text-center home">
    <div class="left logo"></div>
    <h1 class="display-2 announce">
      Welkom
    </h1>
    <p class="lead announce">
      <br>
      <!-- <b>Inschrijvingen zijn gestart (verlengd tot 30 januari 2024)!</b> -->
      <b>De inschrijvingen zijn afgesloten.</b>
      <br>
      <br>
      <!-- <button class="btn btn-lg btn-danger" @click="$router.push({ name: 'Subscription' })">Klik hier om in te schrijven!</button> -->
      <br>
      <!-- <button class="btn btn-lg btn-success" @click="$router.push({ name: 'Program' })">Kijk hier voor het programma</button> -->
      <br>
      <br>
      <br>
      <br>
      <br>
    </p>
  </section>

  <section>
    <p>
      Het Comité Vrijzinnige feesten Groot Lier bestaat uit een groep enthousiaste leerkrachten die in het lager onderwijs met veel toewijding en plezier de cursus niet-confessionele zedenleer aanbieden.
      In hun vrije tijd richten zij ieder jaar opnieuw een onvergetelijke dag in voor hun leerlingen die ervoor kiezen de overgang van de kleuter naar de lagere school (Lentefeest) en deze van lagere naar middelbare school (Feest Vrijzinnige Jeugd) te vieren.
      <br>
      Over heel de wereld wordt stil gestaan bij deze overgangsfeesten. Iedere levensovertuiging viert deze dan ook op zijn eigen unieke manier.
      <br>
      In de cursus niet-confessionele zedenleer zetten wij de mens centraal.
      <br>
      Dit gebeurt ook tijdens onze feesten. De kleine mensen, die later zorgen voor onze mooie toekomst, zetten wij heel graag in de schijnwerpers.
      Tijdens de lessen leren zij dan ook vrij en zelfstandig denken en handelen. Zij gaan op ontdekkingstocht naar zichzelf, de anderen en de wereld om zich heen.
    </p>

    <h2>Andere vrijzinnige verenigingen:</h2>
    <ul>
      <li><a href="https://www.antwerpen-demens.nu/verenigingen/hvv-vrijdenkend-lier/">HVV Vrijdenkend Lier</a></li>
      <li><a href="https://www.antwerpen-demens.nu/verenigingen/hvv-schijnvallei-oostrand/">HVV Schijnvallei (Broechem)</a></li>
      <li><a href="https://www.hujo.be/">Humanistische Jongeren (Hujo)</a>&gt;</li>
      <li><a href="https://rikz.be/">Rikz.z (Raad voor Inspectie en kwaliteitszorg Niet-Confessionele Zedenleer)</a></li>
      <li><a href="https://demens.nu/unie-vrijzinnige-verenigingen/">Unie Vrijzinnige Verenigingen - deMens.nu</a></li>
      <li><a href="https://nczedenleer.be">Niet-Confessionele Zedenleer</a></li>
      <li><a href="https://demens.nu/demens-nu/huisvandemens/">huisvandeMens</a></li>
    </ul>
  </section>

  <div class="alert alert-info alert-fade"></div>
</template>

<script>
export default {
  name: "PageHome"
}
</script>

<style scoped>
.home {
  background: url(../assets/home_bg.png) center/80% no-repeat;
  background-size: cover;
  height: 50rem;
}

.left {
  float: left;
}

.logo {
  background: url(../assets/fakkel.png) no-repeat;
  background-size: contain;
  height: 10rem;
  width: 10rem;
  position: relative;
  top: 1rem;
  left: 1rem;
}

.text-center {
  text-align: center !important;
}

.announce {
  margin-left: 14rem;
}

.display-2 {
  font-size: 5.5rem;
  line-height: 1.2;
}

.lead {
  font-size: 1.25rem;
  color: #363530;
}

.jumbotron {
  margin-bottom: 2rem;
  border-radius: 0.3rem;
}

.attention {
  background-color: #84d4d4;
  border-radius: 5px;
  padding: 2rem;
  margin-bottom: 2rem;
  font-size: larger;
  color: red;
}

.alert-fade {
  height: 50px;
  opacity: 0;
  transition: all .5s;
}
</style>