<template>
  <table class="table table-striped">
    <thead>
      <th scope="col">Feest</th>
      <th scope="col">Leerling</th>
      <th scope="col">Ouder(s)</th>
      <th scope="col">Email</th>
      <th scope="col">Aantal Volwassenen</th>
      <th scope="col">Aantal kinderen</th>
      <th scope="col">Betaald</th>
    </thead>
    <tbody>
      <tr v-for="subscription in subscriptions" :key="subscription.id" :value="subscription.id">
        <td>{{ subscription.student.feest }}</td>
        <td>{{ subscription.student.firstName }} {{ subscription.student.lastName }}</td>
        <td>{{ subscription.parentName }}</td>
        <td>{{ subscription.email }}</td>
        <td>{{ subscription.adultAttendees }}</td>
        <td>{{ subscription.childAttendees }}</td>
        <td>{{ subscription.payed ? "Ja" : "Nee" }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "SubscriptionLine",
  props: {
    schoolId: { type: String, required: true }
  },
  data() {
    return {
      subscriptions: []
    }
  },
  async created() {
    this.subscriptions = await this.$store.dispatch('fetchSubscriptions', {
      schoolId: this.schoolId
    })
  }
}
</script>

<style scoped>

</style>