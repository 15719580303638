import { createApp } from 'vue'
import App from './App.vue'
import { SnackbarService, Vue3Snackbar } from 'vue3-snackbar'
import "bootstrap/dist/css/bootstrap.min.css"
import "vue3-snackbar/styles"
import "bootstrap"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faFire, faTrash, faRedo, faEnvelopeOpenText, faEuroSign, faChild, faPaperPlane, faPlus, faMinus, faFlagCheckered } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from '@/router'
import store from '@/store'
import firebase from 'firebase'
import firebaseConfig from '@/config/firebase'

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

library.add(faUserSecret, faFire, faTrash, faRedo, faEnvelopeOpenText, faEuroSign, faChild, faPaperPlane, faPlus, faMinus, faFlagCheckered)

const app = createApp(App)
app.use(router)
app.use(store)
app.use(SnackbarService)
app.component("vue3-snackbar", Vue3Snackbar)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
