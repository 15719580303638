import firebase from "firebase";

export default {
    namespaced: true,
    state: {
        authId: null,
        email: null,
        authUserUnsubscribe: null,
        authObserverUnsubscribe: null
    },
    getters: {
        authUser: (state, getters, rootState, rootGetters) => {
            return rootGetters['users/user'](state.authId)
        }
    },
    actions: {
        async reauthenticate ({ email, password }) {
            const credential = firebase.auth.EmailAuthProvider.credential(email, password)
            await firebase.auth().currentUser.reauthenticateWithCredential(credential)
        },
        initAuthentication ({ dispatch, commit, state }) {
            if (state.authObserverUnsubscribe) state.authObserverUnsubscribe()
            return new Promise((resolve) => {
                const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
                    dispatch('unsubscribeAuthUserSnapshot')
                    if (user) {
                        await dispatch('fetchAuthUser')
                        resolve(user)
                    } else {
                        resolve(null)
                    }
                })
                commit('setAuthObserverUnsubscribe', unsubscribe)
            })
        },
        signInWithEmailAndPassword (context, { email, password }) {
            return firebase.auth().signInWithEmailAndPassword(email, password)
        },
        async signOut ({ commit }) {
            await firebase.auth().signOut()

            commit('setAuthId', null)
        },
        fetchAuthUser: async ({ commit }) => {
            const userId = firebase.auth().currentUser?.uid
            if (!userId) return

            commit('setAuthId', userId)
            const email = firebase.auth().currentUser?.email
            commit('setAuthEmail', email)

        },
        async unsubscribeAuthUserSnapshot ({ state, commit }) {
            if (state.authUserUnsubscribe) {
                state.authUserUnsubscribe()
                commit('setAuthUserUnsubscribe', null)
            }
        }
    },
    mutations: {
        setAuthId (state, id) {
            state.authId = id
        },
        setAuthEmail(state, email) {
            state.email = email
        },
        setAuthUserUnsubscribe (state, unsubscribe) {
            state.authUserUnsubscribe = unsubscribe
        },
        setAuthObserverUnsubscribe (state, unsubscribe) {
            state.authObserverUnsubscribe = unsubscribe
        }
    }
}