<template>
  <div class="container overview" v-for="school in schools" :key="school.id" :value="school.id">
    <div class="row">
      <div class="col">
        <button class="btn btn-primary" @click="exportToExcel()">Exporteer naar Excel</button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h2>{{ school.name }} {{ school.city }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <subscription-line :school-id="school.id" />
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionLine from "../../components/SubscriptionLine"
import store from '@/store'
import saveAs from "file-saver"
export default {
  name: "AdminSubscriptions",
  components: {SubscriptionLine},
  data() {
    return {
      schools: []
    }
  },
  methods: {
    async exportToExcel() {
      const HEADERS = {
        'x-functions-key': '46gpbB9SGTeHH6EKaf4J1CHibGz15OJW_klKgvPa04BiAzFuWdwA0Q=='
      }

      const res = await fetch(`${process.env.VUE_APP_API_URL}/api/CreateSubReport?id=${store.state.auth.authId}`,
          { headers: HEADERS, method: 'GET' })

      const uri = await res.text()
      saveAs(uri, 'rapport2022.xlsx')
    }
  },
  async created() {
    this.schools = await this.$store.dispatch('fetchSchoolsOfTeacher')
  }
}
</script>

<style scoped>
.overview {
  margin-top: 2rem;
}
</style>