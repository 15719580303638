<template>
  <h3>Je bent uitgelogd.</h3>
  <div class="mb-3">
    <button @click="goToHome()">Ga verder</button>
  </div>
</template>

<script>
  export default {
    name: 'Signout',
    methods: {
      async goToHome() {
        await this.$store.dispatch('auth/signOut')
        this.$router.push({ name: 'Home' })
      }
    }
  }
</script>