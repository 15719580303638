<template>
  <div>
    <div class="container overview" v-for="school in schools" :key="school.id" :value="school.id">
      <div class="row">
        <div class="col">
          <h2>{{ school.name }} {{ school.city }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <student-line :school-id="school.id"></student-line>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentLine from "@/components/StudentLine";

export default {
  name: "Overview",
  components: {StudentLine},
  data() {
    return {
      schools: []
    }
  },
  methods: {
  },
  async created() {
    this.schools = await this.$store.dispatch('fetchSchoolsOfTeacher')
  }
}
</script>

<style scoped>
  .overview {
    margin-top: 2rem;
  }
</style>