import {createStore} from "vuex"
import {findBy, findById, upsert} from "@/helpers"
import firebase from "firebase"
import auth from './modules/auth'

export default createStore({
    modules: {
      auth
    },
    state: {
        schools: [],
        students: [],
        subscriptions: [],
        teachers: []
    },
    getters: {
        school: state => {
            return (id) => {
                const school = findById(state.schools, id)
                return {
                    ...school,
                    get teachers () {
                        return findBy(state.teachers, 'schoolId', school.id)
                    },
                    get students () {
                        return findBy(state.students, 'schoolId', school.id)
                    }
                }
            }
        }
    },
    actions: {
        async createStudent({ commit }, student) {
            student.createdAt = Math.floor(Date.now() / 1000)
            const response = await firebase.firestore().collection('students').add(student)
            commit('setItem', { resource: 'students', item: { ...student, id: response.id } })
        },
        async createSubscription({ commit }, subscription) {
            subscription.createdAt = Math.floor(Date.now() / 1000)
            const response = await firebase.firestore().collection('subscriptions').add(subscription)
            commit('setItem', { resource: 'subscriptions', item: { ...subscription, id: response.id } })
        },
        async createTeacher({ commit }, teacher) {
            const response = await firebase.firestore().collection('teachers').add(teacher)
            commit('setItem', { resource: 'teachers', item: { ...teacher, id: response.id } })
        },
        async payedSubscription({ commit }, { subscriptionId, payedOn }) {
            let db = firebase.firestore()
            const ref = await db.collection('subscriptions').doc(subscriptionId)
            await ref.update({
                payed: true,
                payedOn: payedOn
            })
            ref.onSnapshot(snapshot => commit('setItem', { resource: 'subscriptions', item: snapshot.data }))
        },
        async attestSent({ commit }, subscriptionId) {
            let db = firebase.firestore()
            const ref = await db.collection('subscriptions').doc(subscriptionId)
            await ref.update({
                attestSent: true
            })
            ref.onSnapshot(snapshot => commit('setItem', { resource: 'subscriptions', item: snapshot.data }))
        },
        async updateAttendees({ commit }, {subscriptionId, adultAttendees, childAttendees }) {
            let db = firebase.firestore()
            const ref = await db.collection('subscriptions').doc(subscriptionId)
            await ref.update({
                adultAttendees: adultAttendees,
                childAttendees: childAttendees
            })
            ref.onSnapshot(snapshot => commit('setItem', { resource: 'subscriptions', item: snapshot.data }))
        },
        async updateTeachers({ commit }, { schoolId, teachers }) {
            let db = firebase.firestore()
            const ref = await db.collection('schools').doc(schoolId)
            await ref.update({
                teachers: teachers
            })
            ref.onSnapshot(snapshot => commit('setItem', { resource: 'schools', item: snapshot.data }))
        },
        async deleteStudent({ commit }, studentId) {
            await firebase.firestore().collection('students').doc(studentId).delete()
            commit('deleteItem', { resource: 'students', id: studentId })
        },
        async deleteSubscription({ commit }, subscriptionId) {
            await firebase.firestore().collection('subscriptions').doc(subscriptionId).delete()
            commit('deleteItem', { resource: 'subscriptions', id: subscriptionId })
        },
        fetchAllSchools({ commit }) {
          return new Promise((resolve) => {
              firebase.firestore().collection('schools').orderBy('name').onSnapshot((querySnapshot) => {
                  const schools = querySnapshot.docs.map(doc => {
                      const item = { id: doc.id, ...doc.data() }
                      commit('setItem', { resource: 'schools', item })
                      return item
                  })
                  resolve(schools)
              })
          })
        },
        fetchAllTeachers({ commit }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('teachers').onSnapshot((querySnapshot) => {
                    const teachers = querySnapshot.docs.map(doc => {
                        const item = { id: doc.id, ...doc.data() }
                        commit('setItem', { resource: 'teachers', item })
                        return item
                    })
                    resolve(teachers)
                })
            })
        },
        fetchSchoolsOfTeacher({ commit, rootState }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('schools')
                    .where('teachers', 'array-contains', rootState.auth.authId)
                    .get().then((querySnapshot) => {
                    const schools = querySnapshot.docs.map(doc => {
                        const item = { id: doc.id, ...doc.data() }
                        commit('setItem', { resource: 'schools', item })
                        return item
                    })
                    resolve(schools)
                })
            })
        },
        fetchAllStudents({ commit }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('students').onSnapshot((querySnapshot) => {
                    const students = querySnapshot.docs.map(doc => {
                        const item = { id: doc.id, ...doc.data() }
                        commit('setItem', { resource: 'students', item })
                        return item
                    })
                    resolve(students)
                })
            })
        },
        fetchStudents({ commit }, { schoolId }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('students')
                    .where('schoolId', '==', schoolId).get().then((querySnapshot) => {
                    const students = querySnapshot.docs.map(doc => {
                        const item = { id: doc.id, ...doc.data() }
                        commit('setItem', { resource: 'students', item })
                        return item
                    })
                    resolve(students)
                })
            })
        },
        fetchSubscriptions({ commit }, { schoolId }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('subscriptions')
                    .where('student.schoolId', '==', schoolId).get().then((querySnapshot) => {
                    const subscriptions = querySnapshot.docs.map(doc => {
                        const item = { id: doc.id, ...doc.data() }
                        commit('setItem', { resource: 'subscriptions', item })
                        return item
                    })
                    resolve(subscriptions)
                })
            })
        },
        fetchStudentsByFeest({ commit }, { schoolId, feest }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('students')
                    .where('schoolId', '==', schoolId)
                    .where('feest', '==', feest).get().then((querySnapshot) => {
                    const students = querySnapshot.docs.map(doc => {
                        const item = { id: doc.id, ...doc.data() }
                        commit('setItem', { resource: 'students', item })
                        return item
                    })
                    resolve(students)
                })
            })
        },
        fetchStudent({ commit }, { id }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('students').doc(id).onSnapshot((doc) => {
                    const student = { ...doc.data(), id: doc.id }
                    commit('setItem', { resource: 'students',  student })
                    resolve(student)
                })
            })
        },
        fetchSchool({ commit }, { uid }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('schools')
                    .where('uid', '==', uid)
                    .get().then((querySnapshot) => {
                        const schools = querySnapshot.docs.map(doc => {
                            const item = { id: doc.id, ...doc.data() }
                            commit('setItem', { resource: 'schools', item })
                            return item
                        })

                        resolve(schools[0])
                    })           
            })
        },
        fetchTeacher({ commit }, { uid }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('teachers')
                    .where('uid', '==', uid)
                    .get().then((querySnapshot) => {
                        const teachers = querySnapshot.docs.map(doc => {
                            const item = { id: doc.id, ...doc.data() }
                            commit('setItem', { resource: 'teachers', item })
                            return item
                        })

                        resolve(teachers[0])
                    })
            })
        },
        fetchStudentBySelection({ commit }, { schoolId, firstName }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('students')
                    .where('schoolId', '==', schoolId)
                    .where('FirstNameUpper', '==', firstName).get().then((querySnapshot) => {
                    const students = querySnapshot.docs.map(doc => {
                        const item = { id: doc.id, ...doc.data() }
                        commit('setItem', { resource: 'students', item })
                        return item
                    })
                    resolve(students)
                })
            })
        },
        fetchSubscriptionByStudentId({ commit }, { studentId }) {
            return new Promise((resolve) => {
                firebase.firestore().collection('subscriptions')
                    .where('studentId', '==', studentId).get().then((querySnapshot) => {
                    const subscriptions = querySnapshot.docs.map(doc => {
                        const item = { id: doc.id , ...doc.data() }
                        commit('setItem', { resource: 'subscriptions', item })
                        return item
                    })
                    resolve(subscriptions)
                })
            })
        },
        fetchAdmin({ rootState }) {
            return new Promise(resolve => {
                firebase.firestore().collection('administrators')
                    .where('teacherId', '==', rootState.auth.authId).get().then((querySnapshot) => {
                    const administrators = querySnapshot.docs.map(doc => {
                        const item = { id: doc.id, ...doc.data() }
                        return item
                    })
                    resolve(administrators)
                })
            })
        },
    },
    mutations: {
        setItem (state, { resource, item }) {
            upsert(state[resource], item)
        },
        deleteItem(state, { resource, id }) {
            const index = state[resource].findIndex(i => i.id === id)
            state[resource].splice(index, 1)
        },
        appendStudentToSchool: makeAppendChildToParentMutation({ parent: 'schools', child: 'students' })
    }
})

function makeAppendChildToParentMutation ({ parent, child }) {
    return (state, { childId, parentId }) => {
        const resource = findById(state[parent], parentId)
        resource[child] = resource[child] || []

        if (!resource[child].includes(childId)) {
            resource[child].push(childId)
        }
    }
}